function Logo(){
    return (
        <div className="Logo">

            <div className="image"></div>

            <div className="name">
                <span className="first">Adam</span>
                <span className="last">Fullen</span>
                <span className="tagLine">Web Application Developer</span>
            </div>
        </div>
      );
}
export default Logo;